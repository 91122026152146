import * as React from "react"
import HtmlParser from "react-html-parser"
import { graphql } from "gatsby"

import * as styles from "./portal.module.css"
import { i18n } from "../../../translate/i18n"

import Layout from "../../../components/layout"
import Grid from "../../../components/grid"

function Portal({ location }) {
  return (
    <Layout location={location} headerHome={false}>
      <Grid>
        <h1 className={styles.page_title}>{i18n.t("docs.portal")}</h1>
        <div className={styles.content}>
          <p className={styles.text}>{i18n.t("portal.p1")}</p>
          <p className={styles.text}>{HtmlParser(i18n.t("portal.p2"))}</p>
          <p className={styles.text}>{HtmlParser(i18n.t("portal.p3"))}</p>
          <p className={styles.text}>{i18n.t("portal.p4")}</p>
          <p className={styles.text}>{HtmlParser(i18n.t("portal.p5"))}</p>
          <p className={styles.text}>{HtmlParser(i18n.t("portal.p6"))}</p>
          <p className={styles.text}>{HtmlParser(i18n.t("portal.p7"))}</p>
          <p className={styles.text}>{HtmlParser(i18n.t("portal.p8"))}</p>
          <p className={styles.text}>{i18n.t("portal.p9")}</p>
        </div>
      </Grid>
    </Layout>
  )
}

export default Portal

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "DD/MM/YYYY")
          title
          description
        }
      }
    }
  }
`
